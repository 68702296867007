import { PlatformNames, Platforms, ProfessionalBodies, stage } from "../types";
import { environment as env } from "./environmentBase";

export const environment = {
  ...env,
  platform: Platforms.act,
  appName: PlatformNames[Platforms.act],
  description:
    "ThinkActuary helps get you better prepared for your exam with feedback and indicative marks on your attempts from our panel of experienced markers. Track your performance over time to make sure you are ready on exam day.",
  favicon: "../assets/img/favicons/thinkactuary.ico",
  envName: stage.DEV,
  production: false,
  professionalBodies: [ProfessionalBodies.ASSA],
  subjects: [
    {
      code: "A311",
      name: "Actuarial Risk Management",
      questions: "More than 190",
      reviews: "4,000+",
      professionalBody: "ASSA",
    },
  ],
  testimonials: [
    "The expedient marking process greatly enhanced my learning experience, allowing for quick feedback and improvement.",
    "Your resource was instrumental in my successful completion of the F102 exam.",
    "... the organisation of topics was particularly beneficial. By having the material split by topic, I could concentrate more effectively on my weaker areas, ensuring a well-rounded preparation.",
  ],
  community: "actuarial",
  email: "alice@thinkactuary.com",
  firebase: {
    apiKey: "AIzaSyBYlguUicVFnrLsP4BnQSAwTrU7l4b_690",
    authDomain: "thinkactuary-develop.firebaseapp.com",
    databaseURL: "https://thinkactuary-develop-default-rtdb.firebaseio.com",
    projectId: "thinkactuary-develop",
    storageBucket: "thinkactuary-develop.appspot.com",
    messagingSenderId: "447134283864",
    appId: "1:447134283864:web:5ee2f09920c6a32f15322d",
  },
};
