import { environment as env } from "./act.dev";
import { stage } from "../types";

export const environment = {
  ...env,
  envName: stage.PROD,
  firebase: {
    apiKey: "AIzaSyCwVZ91-VH7rCDJhgTzcA6_A0ExNLAG2VU",
    authDomain: "thinkactuary.com",
    databaseURL: "https://thinkactuarylearn.firebaseio.com",
    projectId: "thinkactuarylearn",
    storageBucket: "thinkactuarylearn.appspot.com",
    messagingSenderId: "909732792696",
    appId: "1:909732792696:web:0161224cfcb17e6649e08d",
    measurementId: "G-4ETCN6GW9W",
  },
  subjects: [
    {
      code: "A311",
      name: "Actuarial Risk Management",
      questions: "More than 190",
      reviews: "4,000+",
      professionalBody: "ASSA",
    },
    {
      code: "F101",
      name: "Health & Care Principles",
      questions: "More than 100",
      reviews: "700+",
      professionalBody: "ASSA",
    },
    {
      code: "F102",
      name: "Life Insurance Principles",
      questions: "More than 140",
      reviews: "4,000+",
      professionalBody: "ASSA",
    },
    {
      code: "F103",
      name: "General Insurance Principles",
      questions: "More than 120",
      reviews: "1500+",
      professionalBody: "ASSA",
    },
    {
      code: "F105",
      name: "Finance and Investment Principles",
      questions: "More than 140",
      professionalBody: "ASSA",
    },
    {
      code: "F106",
      name: "Enterprise Risk Management",
      questions: "More than 100",
      professionalBody: "ASSA",
    },
    {
      code: "F201",
      name: "Health and Care Specialist Applications",
      questions: "More than 30",
      comingSoon: false,
      professionalBody: "ASSA",
    },
    {
      code: "F202",
      name: "Life Insurance Fellowship Applications",
      questions: "More than 30",
      reviews: "850+",
      professionalBody: "ASSA",
    },
    {
      code: "F203",
      name: "General Insurance Fellowship Applications",
      questions: "More than 30",
      reviews: "850+",
      professionalBody: "ASSA",
    },
  ],
};
